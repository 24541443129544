@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "react-phone-input-2/lib/style.css";
@import "swiper/css";
@import "swiper/css/pagination";
@import "swiper/css/navigation";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: black;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 5% !important;
}

.swiper-pagination-bullet {
  width: 16px !important;
  border-radius: 10px !important;
  height: 6px !important;
  background: #ffffff !important;
  opacity: 0.7 !important;
}

.swiper-pagination-bullet-active {
  width: 48px !important;
  border-radius: 100px !important;
  opacity: 1 !important;
  background: white !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(185, 180, 180, 0.6); /* Overlay effect */
  z-index: 9999; /* Ensure it is on top of other content */
}

.circular-progress {
  width: 150px;
  height: 150px;
  border: 4px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fc-view-harness {
  background-color: #ffffff;
}

.fc-col-header-cell {
  background-color: #ffffff;
}

.fc-col-header-cell-cushion {
  color: #7889b2;
}

.fc-daygrid-day {
  background-color: #fafafa;
}

.fc-daygrid-day.has-event {
  background-color: #4485fd1a;
}

.fc-daygrid-day-number {
  color: #1a4187;
}

.fc th,
.fc td {
  border-color: #e6e6e6 !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #e6e6e6;
}

.fc-theme-standard .fc-scrollgrid {
  border-color: #e6e6e6;
}

/* New styles */
.fc-daygrid-day-top {
  padding-top: 10px;
  padding-bottom: 10px;
}

.fc-daygrid-day-events {
  background: none !important;
}

.fc-event-title {
  color: #547196;
}

.fc-toolbar-title {
  color: #547196 !important;
}

.fc-button-primary {
  background-color: transparent !important;
  border: 1px solid #547196 !important;
  color: #547196 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-button-primary:hover {
  background-color: rgba(84, 113, 150, 0.1) !important;
}

.fc-icon {
  color: #547196 !important;
}
